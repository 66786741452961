@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gugi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}
.header .navbar {
  /* background: rgba(3, 49, 108, 0.75) !important; */
  background: #03316c !important;
  box-shadow: -11px 6px 11px rgba(0, 0, 0, 0.25) !important;
  min-height: 77px !important;
}

*:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.header img {
  width: 60% !important;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;

  color: #ffffff !important;
  font-family: "Raleway", sans-serif !important;
}

a.nav-link {
  color: #ffffff !important;
  font-size: 16px !important;
}

.navbar-toggler {
  padding: 0px !important;
  margin: 0px !important;
  color: white !important;
  position: absolute !important;
  top: 26px !important;
}

.custome-btn {
  border: 1px solid rgba(255, 255, 255, 0.83);
  border-radius: 30px;
  background: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 21px; */
  text-align: center;
  /* letter-spacing: 0.05em; */
  color: #ffffff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Raleway", sans-serif !important;
  padding: 3px 15px !important;
}

.custome-btn-lg {
  border: 1px solid rgba(255, 255, 255, 0.83);
  border-radius: 30px;
  background: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 21px; */
  text-align: center;
  /* letter-spacing: 0.05em; */
  color: #ffffff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Raleway", sans-serif !important;
  padding: 5px 40px !important;
}

.custome-btn:hover {
  border: 1px solid rgba(255, 255, 255, 0.83);
}

.custome-btn-lg:hover {
  border: 1px solid rgba(255, 255, 255, 0.83);
}

.main-section {
  /* background: #1b1b1b !important; */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, #03316c 90%); */
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    #08274ff2 90%
  ) !important; */
  background: linear-gradient(0deg, #0a213a 0%, #08274ff2 90%) !important;
}

.bottom-section {
  background: linear-gradient(0deg, #0a213a 0%, #08274ff2 90%) !important;
}

.token-section {
  /* background: linear-gradient(0deg, #0a213a 50%, #08274ff2 90%) !important; */
  background: linear-gradient(0deg, #06121f 10%, #010408f2 90%) !important;
}

.section-title {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 66px;
  /* identical to box height, or 138% */

  margin-bottom: 25px !important;

  letter-spacing: 0.05em;

  color: #ffffff;
  text-align: center !important;
}
.section-title-locker {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* line-height: 66px; */
  /* identical to box height, or 138% */

  letter-spacing: 0.05em;

  color: #ffffff;
  text-align: start !important;
}

.section-description {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  letter-spacing: 0.05em;

  color: #ffffff;

  text-align: center !important;
}

.protocol-card {
  background: rgba(3, 49, 108, 0.72);
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 100% !important;
}

.protocol-card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  /* or 50px */

  text-align: center;

  color: #ffffff;
}

.protocol-card__description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  color: #ffffff;
}

.token-sales-card {
  background: #0f1b28;
  box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  height: 100% !important;
}
.token-sales-card__top {
  /* background: rgba(3, 49, 108, 0.75);
  border-radius: 0px 0px 70% 70% !important; */
  /* background: url("../img/token-sales-bg.png"); */

  display: flex;
  justify-content: center;
  background: rgba(3, 49, 108, 0.75) !important;
  border-radius: 30px 30px 50% 50% !important;
}

.token-sales-card__top img {
  width: 40% !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
}

.token-sales-card__title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  /* font-size: 40px; */
  font-size: 30px !important;
  /* line-height: 140%; */
  /* identical to box height, or 56px */

  text-align: center;

  color: #ffffff;
}

.token-sales-card__description {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;

  color: rgba(255, 255, 255, 0.83);
}

.ecosystem-card {
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
}

.ecosystem-card__top img {
  width: 30% !important;
}

.ecosystem-card__top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
}

.ecosystem-card__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(26, 130, 255, 0.3);
  box-shadow: -2px 5px 12px 2px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  flex-direction: column;
}

.ecosystem-card__title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  /* or 50px */

  text-align: center;

  color: #ffffff;
}

.ecosystem-card__description {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;

  color: rgba(255, 255, 255, 0.83);
}

.disclaimer {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center !important;

  color: #ffffff;
}

.nav-tabs {
  /* border-bottom: 3px solid rgba(26, 130, 255, 0.3); */
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  /* border-bottom: 1px solid rgba(26, 130, 255, 0.3) !important; */
  border: none !important;
}

.current-presales a.nav-link {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 66px;
  /* identical to box height, or 206% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #ffffff;
  background: rgba(3, 49, 108, 0.08);
  /* border: 3px solid rgba(3, 49, 108, 0.75); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.current-presales a.nav-link:hover {
  border: none !important;
  cursor: pointer;
}

.current-presales a.active.nav-link {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 66px;
  /* identical to box height, or 206% */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #ffffff;

  /* border-bottom: 6px solid #0064dd !important; */
  border-bottom: 3px solid white !important;
  background: rgba(3, 49, 108, 0.08);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 10px 10px 0px 0px !important;
  /* border: 3px solid rgba(3, 49, 108, 0.75); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.current-presales .token-input {
  background: rgba(3, 49, 108, 0.55) !important;
  border: 1px solid rgba(198, 198, 198, 0.6) !important ;
  border-radius: 90px !important;
  margin-top: 60px !important;
  color: white !important;
}

.token-select {
  border: 1px solid rgba(198, 198, 198, 0.6) !important;
  border-radius: 10px !important;
  background: none !important;
  /* color: white !important; */
}

.current-presales label {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 66px;
  /* identical to box height, or 367% */

  letter-spacing: 0.05em;

  color: rgba(255, 255, 255, 0.55);
  margin-bottom: -10px !important;
  padding-bottom: 0px !important;
}

.presale-card {
  background: linear-gradient(
    180deg,
    rgba(3, 49, 108, 0.75) 0%,
    rgba(3, 49, 108, 0.75) 77.08%,
    rgba(0, 0, 0, 0.45) 100%
  );
  border: 1px solid rgba(3, 49, 108, 0.75);
  border-radius: 114px;
}
.presale-card img {
  width: 50% !important;
}

.presale-card__status {
  background: rgba(26, 130, 255, 0.3);
  border-radius: 30px;
}

.presale-card__status .fa-circle {
  color: rgba(6, 237, 57, 0.63) !important;
  font-size: 10px !important;
}

.presale-card__status span {
  font-family: "Gugu", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  background: linear-gradient(
    179.38deg,
    #8410f9 0.53%,
    #86ffdb 0.53%,
    #d310d7 23.45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.presale-card__heading {
  font-family: "Gugu", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 39px;

  background: linear-gradient(
    179.38deg,
    #8410f9 0.53%,
    #86ffdb 0.53%,
    #d310d7 23.45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.presale-card__sub-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  /* or 50px */

  text-align: center;

  color: rgba(255, 255, 255, 0.45);
}

.presale-card__sub-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  /* or 45px */

  text-align: center;

  color: #ffffff;
}

.create-token_error{
  color:#ffffff;
  font-size: 12px;
  text-align: end;
}

.presale-card__sub-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  /* line-height: 140%; */
  /* or 45px */

  text-align: center;

  color: #edb506;
}

.presale-card__sub-3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  /* line-height: 140%; */
  /* identical to box height, or 45px */

  text-align: center;

  color: rgba(255, 255, 255, 0.83);
}

.presale-card__hr {
  /* background: rgba(26, 130, 255, 0.3) !important;
  border-radius: 30px !important;
  border: 2px solid rgba(26, 130, 255, 0.3) !important; */

  background: rgba(26, 130, 255, 0.3);
  border-radius: 30px;
  height: 8px;
}

.presale-card__light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;

  color: rgba(198, 198, 198, 0.6);
}

.presale-card__bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.23);
}

.presale-card__white {
  font-family: "Bruno Ace";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}

.presale-card__pool-btn {
  background: rgba(26, 130, 255, 0.3);
  border-radius: 30px;
}

.presale-card__pool-btn:hover {
  background: rgba(26, 130, 255, 0.3);
  border-radius: 30px;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 28px !important;
    line-height: 35px !important;
  }
  .section-title-locker {
    font-size: 22px !important;
  }
  .section-description {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .current-presales a.nav-link {
    font-size: 20px !important;
  }
  .presale-card__light {
    font-size: 15px !important;
  }
  .create-token__heading {
    font-size: 26px !important;
  }
  .custome-btn {
    font-size: 15px !important;
  }

  .create-token__light {
    font-size: 18px !important;
  }
  .create-token__sub {
    font-size: 18px !important;
  }
  .create-token__label {
    font-size: 18px !important;
  }
  .form-check-label {
    font-size: 13px !important;
    white-space: nowrap !important;
  }
  .create-token__sub-label {
    font-size: 15px !important;
  }
  input[type="radio"] {
    margin-top: 3px !important;
  }

  .create-token__last span {
    font-size: 13px !important;
  }

  .presale-card__status span {
    font-size: 18px !important;
  }
  .presale-card__heading {
    font-size: 38px !important;
  }
  .presale-card__sub-heading {
    font-size: 30px !important;
  }
  .presale-card__sub-1 {
    font-size: 26px !important;
  }
  .presale-card__sub-2 {
    font-size: 26px !important;
  }
  .presale-card__sub-3 {
    font-size: 26px !important;
  }
  .presale-card__white {
    font-size: 16px !important;
  }
  .header img {
    width: 150px !important;
  }
  .create-token__bottom span {
    font-size: 15px !important;
  }

  .current-presales .token-input {
    margin-top: 20px !important;
  }
}

.Label-d15-0-2-49 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 66px;
  /* identical to box height, or 275% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #0064dd !important;
  border: none !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 66px; */
  /* or 275% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0064dd !important;
  border: none !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: rgba(198, 198, 198, 0.6) !important;
  border: none !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;

  color: #ffffff !important;
}

.anticon {
  color: white !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: white !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: white !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: white !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: rgba(255, 255, 255, 0.23) !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: rgba(255, 255, 255, 0.23) !important;
}

.ant-steps-item-title::after {
  background: rgba(255, 255, 255, 0.23) !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ffffff !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(198, 198, 198, 0.6) !important;
}

.ant-steps-item-description {
  color: rgba(198, 198, 198, 0.6) !important;
  font-size: 15px !important;
  font-family: "Public Sans";
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  /* or 275% */

  color: #ffffff;
}

.create-token {
  background: rgba(3, 49, 108, 0.44) !important;
  border-radius: 34px !important;
}

.create-token__last span {
  font-family: "Public Sans", sans-serif !important;
  font-size: 20px;
}

.create-token__border-bottom {
  border-bottom: 0.5px solid rgba(198, 198, 198, 0.6) !important;
}

.create-token__light {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* line-height: 66px; */
  /* identical to box height, or 275% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.create-token__heading {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  /* line-height: 66px; */
  /* identical to box height, or 206% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.create-token .form-control {
  border: 1px solid rgba(198, 198, 198, 0.6) !important;
  -webkit-filter: drop-shadow(0px 4px 90px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 4px 90px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 10px;
  background: none !important;
  color: #ffffff;
}

.create-token__sub {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* line-height: 66px; */
  /* identical to box height, or 275% */

  letter-spacing: 0.05em;
  color: #ffffff;
}

.create-token__label {
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  /* font-weight: 700 !important; */
  font-weight: 700 !important;
  font-size: 24px;
  /* line-height: 66px; */
  /* identical to box height, or 236% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.form-check-label {
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  /* line-height: 66px; */
  /* identical to box height, or 236% */

  letter-spacing: 0.05em;

  color: #ffffff;
}
.form-check-label span {
  margin-left: 15px !important;
}

input[type="radio"] {
  width: 20px !important;
  height: 20px !important;
  background: none !important;
  margin-top: 8px;
}

input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  background: none !important;
  margin-top: 8px;
}

.create-token__danger {
  color: #ff5c5c !important ;
}

.create-token__primary {
  color: #cacedd !important;
}

.create-token__sub-label {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* line-height: 66px; */
  /* identical to box height, or 275% */

  letter-spacing: 0.05em;
  color: #ffffff;
}
.create-token__bottom {
  background: rgba(3, 49, 108, 0.75);
  border: 1px solid rgba(26, 130, 255, 0.3);
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.create-token__bottom span {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* line-height: 66px; */
  /* identical to box height, or 330% */

  letter-spacing: 0.05em;

  color: #ffffff;
}

.create-token__next-btn {
  background: rgba(3, 49, 108, 0.75);
  border: 1px solid rgba(26, 130, 255, 0.3);
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  /* identical to box height, or 275% */
  padding: 15px 30px !important;

  letter-spacing: 0.05em;

  color: #ffffff;
}

.locker-section {
  background: linear-gradient(0deg, #0a213a 0%, #08274ff2 90%) !important;
}

.locker-card {
  background: rgba(3, 49, 108, 0.44) !important;
  border-radius: 34px !important;
}

.locker-card .form-control {
  border: 1px solid rgba(198, 198, 198, 0.6) !important;
  -webkit-filter: drop-shadow(0px 4px 90px rgba(0, 0, 0, 0.25)) !important;
          filter: drop-shadow(0px 4px 90px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 10px;
  background: none !important;
}

.locker-card span {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  text-align: center;
  color: rgba(255, 255, 255, 0.83);
}

.locker-card__heading {
  font-family: "Public Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;

  letter-spacing: 0.05em !important;
  color: #ffffff !important;
}

.locker-card .badge {
  border-radius: 30px !important;
  padding: 8px 20px !important;
  background: rgba(90, 176, 255, 0.14) !important;
}

.locker-card .form-check-input {
  position: relative !important;
}

.locker-card .form-check-label {
  line-height: 0px !important;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.lock-card {
  background: rgba(3, 49, 108, 0.44) !important;
  border-radius: 34px !important;
}

.lock-card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff !important;
}

.lock-card__description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.83);
}

@media (max-width: 400px) {
  .lock-card__title {
    font-size: 20px !important;
  }
  .lock-card__description {
    font-size: 13px !important;
  }
}

